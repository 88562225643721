:root {
  --ff-mono : 'Space Mono', monospace;
  

	/* Type */
  --base-font-size : 18px;
	--base-line-height : 1.75;
	--fs-s3 : 0.422rem;
	--fs-s2 : 0.563rem;
	--fs-s1 : 0.75rem;
	--fs-base : 1rem;
	--fs-h5 : 1.333em;
	--fs-h4 : 1.25em;
	--fs-h3 : 2.369em;
	--fs-h2 : 3.157em;
	--fs-h1 : 4.209em;

  --yellow : #FFF45C;
  --blue-light : #C8E7F1;
  --blue-mid : #4696D0;
  --blue-dark : #3B6B90;
  --almost-black : #151623;
  --black: #000000;
  --white: #ffffff;

  --gutter : 2rem;
  --spacing-block: 1rem;
  --spacing-inline: 2rem;

  --max-width-form : 26rem;
  --max-width-form-inner : 26rem;
  --max-width-form-wide : 48rem;

  --body-bg : var(--black);
  --body-bg-alt : var(--almost-black);
  --body-text : var(--white);

  --highlight : var(--yellow);
  --highlight-alt : var(--blue-light);
  --highlight-subtle : var(--blue-dark);
  --win : var(--blue-mid);
  --lose : var(--black);
  --tie : var(--blue-dark);

  --footer-bg : var(--black);
  --footer-color : var(--white);

}

/* Light mode! */
@media (prefers-color-scheme: light) {
  :root {
    --body-bg : var(--white);
    --body-bg-alt : var(--blue-light);
    --body-text : var(--black);

    --highlight-alt : var(--blue-mid);
    --highlight-subtle : var(--blue-light);

    --footer-bg: var(--blue-mid);

    --win : var(--yellow);
    --lose : var(--blue-mid);
    --tie : var(--white);
  }


}

* {
  box-sizing: border-box;
}

body {
  font-family: var(--ff-mono);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;

  background-color: var(--body-bg);
  color: var(--body-text);

  margin: 0;
  padding: 0;
}

.layout {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;

  background-color: var(--footer-bg);
  color: var(--footer-color);
  padding-inline: var(--gutter);
  padding-block: 0.5rem;
  width: 100%;
  font-size: var(--fs-s1);
  z-index: 3;
}
footer a {
  color: var(--highlight);
}

img { display: block; }

header {
  padding-block: 2rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  min-height: 8rem;
}

header img {
  max-width: 3rem;
  height: auto;
}
header h1 span {
  display: block;
  font-size: 0.65em;
  font-weight: 400;
}

h1,h3,h4,h5,h6 {
  margin: 0;
}

h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.form-wrapper {
  max-width: var(--max-width-form);
  width: 100%;
  height: 100vh;

  padding-bottom: 4rem;
  background-color: var(--body-bg);

  overflow: scroll;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  z-index: 2;
}
.form-inner {
  padding-inline: 2rem;
  max-width: var(--max-width-form);
  margin: 0 0 0 auto;

}
.results-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: calc(100% - var(--max-width-form));

  display: flex;
  align-items: stretch;
}

.results-inner {
  padding-inline: 4rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
  width: 100%;
  margin-top: 9rem;
  max-width: 48rem;
  margin-inline: auto;
}

.results-inner h2 {
  width: 100%;
}


label, input, button {
  cursor: pointer;
}

button {
  padding: 1rem;
  border: solid 1px var(--highlight-alt);
  width: 100%;
  background-color: transparent;
  color: var(--highlight-alt);
  text-transform: uppercase;
  font-size: 1.1rem;

  transition: all 150ms ease-in-out;
}
button:hover {
  background-color: var(--highlight);
  border-color: var(--highlight);
  color: var(--black);
}

.form .pokemon > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2rem;
  margin-bottom: 1rem;
}

.form .pokemon > div > div, 
.form .pokemon > div > label {
  width: 100%;
}

.form .pokemon {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  border: 1px solid var(--highlight-alt);
}

.radio-group label {
  width: 100%;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  text-align: center;

  background-color: transparent;
}
/*
.radio-group label:first-of-type {
  border-radius: 5px 0 0 5px;
}
.radio-group label:last-of-type {
  border-radius: 0 5px 5px 0;
}
*/

.radio-group input[type="radio"] {
  display: none;
}

.radio-group input[type="radio"]:checked + label {
  background-color: var(--highlight);
  color: var(--body-bg);
}

.form {
  font-size: 0.9rem;
}

input, select {
  width: 100%;
  padding: 0.5rem 0.5rem;
  background-color: transparent;

  border: 1px solid var(--highlight-alt);
  color: var(--body-text);

  font-size: 1rem;
}
input, select, .radio-group {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
input:focus {
  outline: none;
  border-color: var(--highlight);
}

.chart {
  border: solid 1px white;
  padding: var(--gutter);
  width: 100%;
  margin-top: 2rem;
}
.chart > .chart-line:first-child {
  font-weight: 600;
  font-size: var(--fs-h5);
}

.chart-header {
  width: 100%;
}
.chart-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;
  margin-bottom: 1.5rem;
}
.chart-line > div:first-of-type {
  max-width: 7rem;
  width: 100%;
}
.line-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
}
.line-container span {
  padding-left: 1rem;
  min-width: 5rem;
}
.line-container .line {
  text-align: left;
  padding: 0.25rem 0.25rem;
  overflow: hidden;
  width: 100%;

  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  min-height: 2.5rem;
}
.line-container .line::after {
  content: '';
  width: var(--after-width);
  height: 100%;
  background-color: var(--blue-dark);
  position: absolute;
  left:0;
  z-index: -1;

  transition: all 150ms ease;
}
.line-container .line.choiced::after {
  /*background-color: var(--blue-mid);*/
  filter: brightness(1.5);
}
.line-container .line.win::after { background-color: var(--win); }
.line-container .line.tie::after { background-color: var(--tie); }
.line-container .line.lose::after { background-color: var(--lose); }

.line .img-scarf {
  display: inline-block;
  max-width: 2rem;
  margin-right: 1rem;
}

.chart-line > div:last-of-type {
  padding-left: 1rem;
  min-width: 8rem;
}
.chart-line .speed {
  font-weight: bold;
  font-size: var(--fs-h5);
}
.form .pokemon > .row.advanced {
  transition: all 300ms ease-in-out;
  max-height: 0rem;
  overflow: hidden;
  margin-bottom: 0;

  padding-inline: 0.5rem;
  background-color: var(--highlight-subtle);
}
.form .pokemon > .row.advanced.show {
  max-height: 6rem;
  padding-block: 0.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
}

.form .pokemon > .row.advanced.show + .row.advanced.show {
  margin-top: 0;
}

label.wide > div {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

label.wide small {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.form .pokemon .row.checkboxes {
  text-align: center;
}

.form .pokemon > div > label.narrow {
  width: calc(33% + 1rem);
}
.form .pokemon > div > label.wide {
  width: calc(67% - 1rem);
}

button.btn-subtle {
  font-size: 0.8rem;
  padding: 0.5rem;
  margin-top: 1rem;
  text-transform: none;
  border: none;
}

.chart-line-enter {
  border: red 5px solid !important;
  opacity: 0;
}
.chart-line-enter-active {
  border: red 5px solid !important;
  opacity: 1;
  transition: opacity 200ms;
}
.chart-line-exit {
  border: red 5px solid !important;
  opacity: 1;
}
.chart-line-exit-active {
  border: red 5px solid !important;
  opacity: 0;
  transition: opacity 200ms;
}

/* Animations! */
.form-wrapper, .results-wrapper {
  transition: all 250ms ease;
}

.form-wrapper.results-hidden {
  max-width: calc(50vw + (var(--max-width-form) / 2) );
  width: 100%;
  box-shadow: 5px 0px 5px -2px rgb(0 0 0 / 0%);
}
.form-wrapper.results-visible {
  box-shadow: 5px 0px 5px -2px rgb(0 0 0 / 10%);
}

.form-wrapper.results-hidden + .results-wrapper {
  background-color: var(--body-bg);
  opacity: 0;
}
.results-wrapper.is-visible {
  background-color: var(--body-bg-alt);
}
.results-wrapper.is-displayed {
  opacity: 1;
}

button.btn-side-up {
  display: none;
  position: absolute;
  top: 1rem;
  right: 2rem;

  width: auto;
  padding: 0.25rem;
  line-height: 1;
  border: none;
  border-radius: 100%;
}
button.btn-side-up svg { 
  display: block;
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (min-width: 1290px) {
  :root {
    --max-width-form : 45vw;
  }
  .form-wrapper.results-hidden {
    max-width : calc(50vw + (var(--max-width-form-inner) / 2) );
  }
  .form-wrapper .form-inner {
    max-width : var(--max-width-form-inner);
  }

}

@media screen and (max-width: 64rem) { /* 960px */
  :root {
    --max-width-form: 100%;
    --max-width-form-inner : 26rem;
  }
  header {
    justify-content: center;
  }
  /*
  .layout {
    overflow: auto;
    max-height: max-content;
  }*/  
  .results-hidden, .results-wrapper {
    max-width: 100%;
  }
  .form-inner {
    max-width : var(--max-width-form-inner);
    margin-inline: auto;
    transition: max-width 150ms ease;
  }
  .form-wrapper {
    position: initial;
  }

  /* Results */
  button.btn-side-up {
    display: block;
  }
  .results-wrapper {
    overflow: hidden;
    transition-duration: 500ms;
    top: 100vh;
    left:0;

    max-width: 100%;
    box-shadow: 5px 0px 5px -2px rgb(0 0 0 / 0%);
  }
  .results-wrapper.is-visible {
    box-shadow: 0px -2px 4px -1px rgb(0 0 0 / 10%);
  }
  .results-wrapper.is-visible.is-tall {
    top: 10rem;
  }
  .results-wrapper.is-visible.is-short {
    top: 75vh;
  }
  .results-inner {
    margin-top: 0rem;
    padding-inline: var(--gutter);
    max-width: var(--max-width-form-wide);
  }

  .is-short button.btn-side-up {
    transform: rotate(180deg);
  }

  /* Subtle style changes */
  .form .pokemon > div {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 64rem) and (min-width: 41.15rem) {
  .results-visible .form-inner {
    max-width : var(--max-width-form-wide);
    padding-bottom: 4rem;
  }
  .results-visible .form {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gutter);
  }
  .results-visible .pokemon {
    flex-basis: calc(50% - var(--gutter) / 2);
    width: calc(50% - var(--gutter) / 2);
  }
  .results-visible button:not(.btn-subtle) {
    flex-basis: calc(75% - var(--gutter) / 2);
    width: calc(75% - var(--gutter) / 2);
  }
  .results-visible button.btn-subtle {
    margin-top: 0;
    flex-basis: calc(25% - var(--gutter) / 2);
    width: calc(25% - var(--gutter) / 2);
    border: 1px solid var(--highlight-subtle);
  }
  .results-visible .form .pokemon {
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 41.15rem) { /* 660px */
  .form .pokemon {
    margin-bottom: 2rem;
  }
  .chart {
    border: none;
    padding: 2rem 0 4rem;
  }
  .chart .chart-line:first-child {
    font-size: var(--fs-base);
  }
  .chart .chart-line:first-child .chart-header {
    display: none;
  }
  .chart .chart-line:first-child > div:last-child {
    margin-left: auto;
    text-align: right;
  }
  .chart-line:first-child {
    margin-bottom: 0.5rem;
  }
  .chart-line:not(:first-child) {
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
  }
  .chart-line > div:first-child {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 0.25rem;
  }
  .chart-line .line-container {
    max-width: calc(100% - 5.5rem);
  }
  .chart-line > div.speed {
    font-size: var(--fs-h6);
    max-width: 5.5rem;
    min-width: 5.5rem;
    text-align: right;
  }
  .results-wrapper {
    position: fixed;
    z-index: 5;
    overflow: scroll;
    transition-duration: 350ms;
  }
  .results-wrapper.is-visible.is-tall {
    top: 10vh;
  }
  .results-wrapper.is-visible.is-short {
    top: 100vh;
    overflow: visible;
  }
  .results-wrapper.is-visible button.btn-side-up {
    position: fixed;
    top: unset;
    bottom: 2rem;
    background-color: var(--highlight);
  }
  .results-wrapper.is-visible.is-tall button.btn-side-up {
    color: var(--body-text);
  }

  .results-inner h2 {
    font-size: var(--h3);
  }

}


@media screen and (max-width: 22rem) {
  :root {
    --base-font-size: 16px;
  }
}


.side-notification {
  position: fixed;
  z-index: 10000;
  /*bottom: 1rem;*/
  right: 1rem;
  padding: 1rem;
  border: 1px solid var(--highlight);
  background-color: var(--highlight);
  color: var(--body-bg);
  box-shadow: 2px 2px 7px 0px rgb(255 255 255 / 80%);
  max-width: 320px;
  width: 100%;

  display: none;
  opacity: 0;
  bottom: -2rem;

  transition: all ease 250ms;
}
.side-notification.opening, .side-notification.open, .side-notification.closing {
  display: block;
}
.side-notification.open {
  opacity: 1;
  bottom: 1rem;
}

.side-notification .inner { position: relative; }
.side-notification .close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  cursor: pointer;
}
.side-notification h4 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.15;
  width: calc(100% - 1rem);
}
.side-notification p {
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.2;
}
.side-notification svg {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  --icon--color: currentColor;
  --icon--accent: var(--highlight);
}


@media (prefers-color-scheme: light) {
  .side-notification {
    box-shadow: 2px 2px 4px -2px rgb(0 0 0 / 20%);
    color: var(--body-color);
  }
  .radio-group input[type="radio"]:checked + label {
    color: var(--highlight-alt);
  }
}